import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input, HostListener, Inject, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, filter, first } from 'rxjs';
import { QuoteStatus } from '../../../api';
import { appActions } from '../../../store/actions/app.actions';
import { selectApp } from '../../../store/selectors/app.selectors';
import { IMainEventData } from '../../models/events';
import { EmptyMessageConfig, EmptyMessageActionTriggers } from '../../models/misc';
import { EventRequestService } from '../../services/event-request/event-request.service';
import { EventRequestSingleType, EventRequestType } from '../../services/event-request/event-request.type';
import { SupplierInvitesService } from '../../services/supplier-invites/supplier-invites.service';
import { WindowService } from '../../services/window/window.service';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared.module';
import { eventActions } from '../../../store/actions/event.actions';
import { EventRequestDialogComponent } from '../dialogs/event-request-dialog/event-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-event-request-inbox',
  templateUrl: './event-request-inbox.component.html',
  styleUrls: ['./event-request-inbox.component.scss'],
  standalone: true,
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [MaterialModule, CommonModule, SharedModule]
})
export class EventRequestInboxComponent implements OnInit {
  @Input() activeData: EventRequestSingleType[] = [];
  @Input() inactiveData: EventRequestSingleType[] = [];
  activeTab = 0;
  emptyMessageConfig: EmptyMessageConfig;
  eventRequestActive: boolean;
  innerWidth: number;
  isError = false;
  isLoading$: Observable<boolean>;
  isMobile = false;
  showInterestedCounter: boolean;

  @Output() newRequest = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.isMobile = this._windowService.isMobile();
  }

  constructor(
    public eventRequestService: EventRequestService,
    @Inject(DOCUMENT) readonly document: Document,
    private _router: Router,
    private _store: Store,
    private _windowService: WindowService,
    private _supplierInvitesService: SupplierInvitesService,
    private _sso: ScrollStrategyOptions,
    public dialog: MatDialog
  ) {
    this.showInterestedCounter = false;
    this.onResize();
  }

  ngOnInit() {
    this.isLoading$ = this._store.select(selectApp.getIsLoading);
    this.isMobile = this._windowService.isMobile();
    this.emptyMessageConfig = {
      icon: {
        name: 'ate-alert-v2',
        class: 'icon_alert'
      },
      title: 'Unknown Error: Something went wrong'
    };
    this.tabSelected();
  }

  get window(): Window {
    return this.document.defaultView;
  }

  tabSelected(index = 0): void {
    this.activeTab = index;
    this.setEmptyState(index);

    index === 0 ? this.getEventRequestsActive() : this.getEventRequestsInactive();
  }

  getEventRequestsActive(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.isError = false;

    this.eventRequestService
      .getActiveEventRequests()
      .pipe(
        filter(event => event !== undefined),
        first()
      )
      .subscribe({
        next: response => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          this.activeData = response.data;
          this.showInterestedCounter = response.data.some(event => event.liveInterestCount > 0);
          this.eventRequestActive = true;
          this._store.dispatch(eventActions.SetEventFailure());
        },
        error: err => {
          console.error(err);
          this._store.dispatch(appActions.DecrementLoadingCount());
          this.isError = true;
          this._store.dispatch(eventActions.SetEventFailure());
        }
      });
  }

  getEventRequestsInactive(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());

    this.eventRequestService
      .getInactiveEventRequests()
      .pipe(
        filter(event => event !== undefined),
        first()
      )
      .subscribe({
        next: response => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          this.inactiveData = response.data;
          this.eventRequestActive = false;
          this._store.dispatch(eventActions.SetEventFailure());
        },
        error: err => {
          console.error(err);
          this._store.dispatch(appActions.DecrementLoadingCount());
          this.isError = true;
          this._store.dispatch(eventActions.SetEventFailure());
        }
      });
  }

  openEventRequest(eventRequest: EventRequestSingleType, hasInvites: boolean, selectedEventRequest: EventRequestType): void {
    let redirectTo: string;

    const showQuotes: boolean =
      eventRequest.quotes.length &&
      eventRequest.quotes.some(
        quote => quote.quoteOrganiserStatus !== QuoteStatus.REJECTED_FUTURE && quote.quoteOrganiserStatus !== QuoteStatus.REJECTED_PAST
      );

    if (showQuotes) {
      redirectTo = 'quotes';
    } else {
      if (eventRequest.interests.length) {
        redirectTo = 'interested';
      } else if (eventRequest.bookings.length) {
        redirectTo = 'bookings';
      } else {
        redirectTo = 'suggested';
      }
    }
    this._router.navigateByUrl(`event-request/${eventRequest.event.uuid}/${selectedEventRequest.eventRequestUuid}/${redirectTo}`);
  }

  /**
   * Redirection to the event request page based on quotes, bookings, suggested and interested
   * @param selectedEventRequest
   */
  setRedirection(selectedEventRequest: EventRequestType) {
    combineLatest([
      this.eventRequestService.getRequest(selectedEventRequest.eventUuid, selectedEventRequest.eventRequestUuid),
      this._supplierInvitesService.getInvites(selectedEventRequest.eventRequestUuid, 30)
    ])
      .pipe(first())
      .subscribe({
        next: ([eventRequest, invites]) => {
          if (eventRequest && invites) {
            this.openEventRequest(eventRequest, invites.length > 0, selectedEventRequest);
          }
        },
        error: err => {
          console.error('Could not get data: ', err);
        }
      });
  }

  placeNewRequest() {
    this._store
      .select(selectApp.getEvents)
      .pipe(first())
      .subscribe({
        next: events => {
          events?.length > 0 ? this.openNewRequestDialog(events) : this.openRequestQuotes();
        },
        error: err => {
          console.error('Could not retrieve events from store: ', err);
        }
      });
  }

  getLastRowItemCount(dataLength = 0): number {
    return dataLength % 3 || 3;
  }

  /**
   * Set empty state if list loaded through main event
   * @param selectedIndex
   */
  setEmptyState(selectedIndex: number) {
    switch (selectedIndex) {
      case 0:
        this.emptyMessageConfig = {
          image: '../../../../../assets/images/events-empty-state.png',
          title: 'Submit a request to get started!',
          message: 'Fill out a simple form to start finding the best suppliers for your event.',
          class: 'empty-state',
          actions: [
            {
              icon: 'ate-plus-v3',
              class: 'btn-action btn-action-md btn-primary',
              text: 'New Service Request',
              trigger: EmptyMessageActionTriggers.NEW_REQUEST
            }
          ]
        };
        break;
      case 1:
        this.emptyMessageConfig = {
          icon: {
            name: 'ate-check-circle-v2',
            class: 'tw-size-6'
          },
          title: 'You don’t have any inactive service requests for this event',
          class: 'empty-state'
        };
        break;
    }
  }

  receiveActionTrigger(event) {
    if (event === EmptyMessageActionTriggers.NEW_REQUEST) {
      this.placeNewRequest();
    }
  }

  private openRequestQuotes() {
    this._router.navigateByUrl('new-request');
  }

  private openNewRequestDialog(events: IMainEventData[]) {
    this.dialog.open(EventRequestDialogComponent, {
      width: '500px',
      height: this.innerWidth > 1023 ? 'auto' : '99vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'event-request-dialog',
      scrollStrategy: this._sso.noop(),
      data: {
        events
      }
    });
  }
}
